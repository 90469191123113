@import '../../styles/colours';
@import '../../styles/mq';
@import '../../styles/variables';

.ButtonLink {
  display: inline-block;
  padding: $grid * 2 $grid * 4;
  box-sizing: inherit;
  cursor: pointer;
  text-align: center;
  margin-bottom: $grid * 2;
  line-height: 1.35;
  min-width: $button-min-width;

  @include mq($from: M) {
    margin-bottom: 0;
  }
}

.primary {
  background-color: $boost-blue;
  background: linear-gradient(90deg, $gradiant-1-A 0%, $gradiant-1-B 100%);
  color: $white;

  &:hover,
  &:active {
    background-color: $boost-blue--hover;
  }
}

.secondary {
  background-color: $slate-grey;
  color: $white;

  &:hover,
  &:active {
    background-color: $slate-grey--hover;
  }
}

.outlineLight {
  color: $white;
  border: solid 1px $white;

  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.outlineDark {
  color: $slate-grey;
  border: solid 1px $slate-grey;

  &:hover,
  &:active {
    background-color: rgba(82, 82, 91, 0.1);
  }
}

.compact {
  // line-height: $button-compact-height;
  padding-top: $grid;
  padding-bottom: $grid;
  min-width: $button-compact-min-width;
}
