@import '../../styles/colours';
@import '../../styles/mq';
@import '../../styles/rem';
@import '../../styles/typography';
@import '../../styles/variables';

.Nav {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: $nav-padding-small 0;
  background-color: $boost-blue;
  background-size: cover;
  background-image: url('../../assets/boostPatternBlueMed.png');
  background-position: center center;
  background-repeat: no-repeat;
  color: $white;
  z-index: $zindex-nav;
  overflow: scroll;

  @include mq($from: M) {
    padding: $nav-padding;
  }
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.link {
  color: $white;
  text-decoration: none;
  padding: $grid $grid * 4;
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  transition: background-color $transition-speed $transition-timing;
  @include rem('font-size', $font-size-nav);
  line-height: 1.5;

  &:hover,
  &:active {
    color: $white;

    .linkImage {
      opacity: 1;
    }
  }
}

.linkImage {
  display: none;

  @include mq($from: M) {
    display: inline-block;
    vertical-align: middle;
    opacity: 0;
    transition: opacity $transition-speed $transition-timing;
    line-height: 0;
  }
}

.linkText {
  padding-left: $grid * 3;
  display: inline-block;
  vertical-align: middle;
}

.toggleTitle {
  display: none;

  @include mq($from: M) {
    display: inline;
  }
}

.toggle {
  padding: 0 $grid;
  margin-bottom: 0;
  height: 40px;
  @include rem(font-size, $font-size-x-large);
  @include rem(line-height, 22px);
  color: $slate-grey;
  border-left: 1px solid $platinum;
  transition: border $transition-speed $transition-timing;
  fill: $boost-blue;

  @include mq($from: M) {
    padding: 9px 19px 9px $grid * 2;
  }

  &:hover,
  &:active {
    color: $boost-blue--hover;
    border-left: 1px solid $medium-grey;
    fill: $boost-blue--hover;
  }
}

.toggleImage {
  display: inline-block;
  margin-top: -3px;
  margin-left: 8px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  line-height: 0;

  svg {
    width: 16px;
    height: 16px;
  }

  @include mq($from: S) {
    margin-left: 8px;
    width: 15px;
    height: 15px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.closeButton {
  position: absolute;
  top: $nav-close-padding-small;
  right: $nav-close-padding-small;
  padding: $close-button-padding;
  height: $close-button-image-size + ($close-button-padding * 2 + 4);
  width: $close-button-image-size + ($close-button-padding * 2 + 4);
  color: $white;
  background-color: $boost-blue;
  text-align: center;

  @include mq($from: M) {
    top: $nav-close-padding;
    right: $nav-close-padding;
  }

  &:hover {
    background-color: $white--hover;
  }

  div {
    line-height: 0;
    text-align: center;
  }

  svg {
    height: $close-button-image-size;
    width: $close-button-image-size;
  }
}
