@import './mq';
@import './variables';

.grid {
  display: flex;
  justify-content: space-between;
}

@supports (display: grid) {
  .grid {
    display: grid;
    justify-content: inherit;

    & > * {
      align-self: start;
    }
  }
}

@mixin responsive-cols($breakpoint, $columns, $padding: ($grid * 2)) {
  @if (map-has-key($mq-breakpoints, $breakpoint)) {
    @include mq($from: $breakpoint) {
      // $size: (map-get($mq-breakpoints, $breakpoint) / $columns) -
      //   ($padding * ($columns - 1));
      $size: calc(#{100% / $columns} - #{$padding * ($columns - 1)});

      // Fallback for IE11
      flex-direction: row;
      flex-wrap: wrap;

      & > * {
        max-width: $size;
        flex-basis: $size;
      }

      // Stop flex layout from interfering with the grid size
      @supports (display: grid) {
        > * {
          max-width: 100%;
          flex-basis: 100%;
        }
        grid-template-columns: repeat($columns, 1fr);
      }
    }
  } @else {
    @warn "No breakpoint for "+$breakpoint;
  }
}

.grid--resp {
  flex-direction: column;

  & > * {
    flex-grow: 0;
    flex-shrink: 0;
  }

  @include responsive-cols($breakpoint: S, $columns: 2, $padding: $grid);
  @include responsive-cols($breakpoint: M, $columns: 3, $padding: $grid);
}
.grid--2-col {
  // Fallback for IE11
  align-items: baseline;
  align-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;

  // Stop flex layout from interfering with the grid size
  @supports (display: grid) {
    > * {
      flex-basis: 100%;
    }
    grid-template-columns: repeat(2, auto);
  }
}
.grid--resp-2-col {
  @include mq($from: M) {
    // Fallback for IE11
    align-items: baseline;
    align-content: flex-end;
    // align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      flex-basis: 48%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    // Stop flex layout from interfering with the grid size
    @supports (display: grid) {
      > * {
        flex-basis: 100%;
      }
      grid-template-columns: repeat(2, auto);
    }
  }
}
.grid--resp-2-col-eqw {
  @include mq($from: M) {
    // Fallback for IE11
    align-items: baseline;
    align-content: flex-end;
    // align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      flex-basis: 48%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  @include mq($until: M) {
    display: block;
  }
  @supports (display: grid) {
    @include mq($from: M) {
      grid-template-columns: 1fr 1fr;

      > * {
        flex-basis: 100%;
      }
      > *:last-child() {
        margin-bottom: 0;
      }
    }
  }
}
.grid--resp-3-col {
  @include mq($from: L) {
    // Fallback for IE11
    align-items: baseline;
    align-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    > * {
      flex-basis: 30%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    // Stop flex layout from interfering with the grid size
    @supports (display: grid) {
      > * {
        flex-basis: 100%;
      }
      grid-template-columns: repeat(3, auto);
    }
  }
}
.grid--space-resp {
  justify-content: center;

  @include mq($from: L) {
    justify-content: space-between;
  }
}
.grid--space-between {
  justify-content: space-between;
}
.grid--space-around {
  justify-content: space-around;
}
.grid--center {
  justify-content: center;
}

@supports (display: grid) {
  .grid--space-between {
    justify-content: stretch;
    grid-gap: $grid * 2;
  }

  .grid--col-gap-block {
    grid-column-gap: $block;
  }

  .grid--col-gap-lg {
    grid-column-gap: $grid;

    @include mq($from: M) {
      grid-column-gap: $grid * 4;
    }
    @include mq($from: L) {
      grid-column-gap: 104px;
    }
  }

  .grid--col-gap-grid {
    grid-column-gap: $grid;
  }
}
// We could add grid-row-gap styles but then we'd have to offset IE11 fix for the margin on each of the items
.grid--row-gap {
  > * {
    margin-bottom: $grid * 2.5;
  }
}
.grid--row-gap-grid-3 {
  > * {
    margin-bottom: $grid * 2.5;
  }
}
