// Main colours
$white: #ffffff;
$black: #52525b; // black text colour
$grey: #e8e8e8; // default light grey
$light-grey: #f5f5f5; // apparently, we need 50 shades of grey... this one's latest
$dark-grey: #898989; // label on white background
$medium-grey: #c2c8cf; // label on blue background
$maroon: #cb3c6f;
$yellow: #fbba20;
$purple: #8e649f;

// Rebrand Colours
$boost-blue: #3438c0;
$midnight-blue: #000532;
$slate-grey: #52525b;
$platinum: #e6e6eb;
$ice-blue: #afc5ff;
$cool-blue: #666aff;
$dead-blue: #00323c;

// Gradiant Colours
$gradiant-1-A: $boost-blue;
$gradiant-1-B: $cool-blue;
$gradiant-2-A: $white;
$gradiant-2-B: $ice-blue;

$gradiant-1-A--hover: lighten($boost-blue, 15%);
$gradiant-1-B--hover: lighten($cool-blue, 15%);
$gradiant-2-A--hover: lighten($white, 15%);
$gradiant-2-B--hover: lighten($ice-blue, 15%);

// Brand Variables
$brand-primary: $boost-blue;
$primary: #1c1f4c;

// Hover colours
$white--hover: $medium-grey;
$black--hover: lighten($black, 25%);

$boost-blue--hover: $boost-blue; // lighten($boost-blue, 25%);
$slate-grey--hover: lighten($slate-grey, 25%);

$red: #ff5252;

$error: $red;

// Section colour
$info: $cool-blue;
$info-foreground: $white;
$info-foreground--secondary: $platinum;

$summary: $slate-grey;
$summary-foreground: $white;
$summary-foreground--secondary: $medium-grey;

$alert: $midnight-blue;
$alert-foreground: $white;
$alert-foreground--secondary: $white--hover;

$secondary: $midnight-blue;
$secondary-foreground: $white;
$secondary-foreground--secondary: $white--hover;
