@import '../../styles/mq';
@import '../../styles/variables';

.Logo {
  display: block;
  width: 99px;
  height: 45px;

  @include mq($from: M) {
    height: $logo-height;
    width: $logo-width;
  }
}

.LogoContainer {
  //padding: 14px $grid 14px;
  //margin-left: -$grid; // align the logo image to the left of its container
  margin-right: 6px;
  min-width: 40%;
}
