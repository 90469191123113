@import '../node_modules/normalize-scss/sass/normalize';

@import './styles/accordion';
@import './styles/colours';
@import './styles/grid';
@import './styles/positioning';
@import './styles/rem';
@import './styles/reset';
@import './styles/typography';

/**
 * 1. Sets the default font size back to 16px (unless $font-size-default has
 *    changed value). This is the only time `em` should be used to define the
 *    font-size. This has to be `em` because of a bug in Chrome described in
 *    a link below.
 *    https://code.google.com/p/chromium/issues/detail?id=320754
 */
body {
  margin: 0;
  padding: 0;
  font-size: $font-size-body-em;
  font-family: $font-family-sans-serif;
  /* 1 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.02em;
  color: $black;
}

/**
 * 1. Makes REM units easier to work with because 1rem = 10px.
 */
html {
  box-sizing: border-box;
  font-size: 62.5%;
  /* 1. */
  width: 100% !important;
  line-height: $default-line-height;
}

.no-scroll {
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5 {
  color: $slate-grey;
  font-weight: 800;
  line-height: $default-line-height;
}

h1,
.heading-1 {
  @include rem(font-size, $font-size-mobile-h1);
  line-height: 1.35;
}

h2,
.heading-2 {
  @include rem(font-size, $font-size-mobile-h2);

  @include mq($from: M) {
    @include rem(font-size, $font-size-h2);
  }
}

form h2 {
  @include rem(font-size, $font-size-body);

  @include mq($from: M) {
    @include rem(font-size, $font-size-form-heading);
  }
}

h3,
.heading-3 {
  @include rem(font-size, $font-size-mobile-h2);

  @include mq($from: M) {
    @include rem(font-size, $font-size-h3);
  }
}

h4,
.heading-4 {
  @include rem(font-size, $font-size-mobile-h2);
  margin-bottom: $grid * 2.5;

  @include mq($from: M) {
    @include rem(font-size, $font-size-h4);
    margin-bottom: 0;
  }
}

p {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

ul {
  margin: 0;
  padding: 0;
}

.doubleSpace {
  margin-top: $grid * 2;
  margin-bottom: $grid * 2;
}

:focus {
  outline-offset: 2px;
  outline: 2px solid $primary;
}

a {
  text-decoration: underline;
  color: $boost-blue;
  text-align: left;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;

  &:hover,
  &:active {
    color: $boost-blue--hover;
  }

  &.highlight {
    color: $boost-blue;

    &:hover,
    &:active {
      color: $boost-blue--hover;
    }
  }
}

*:disabled,
.disabled {
  pointer-events: none !important;
  cursor: pointer;
}

.strong {
  font-weight: 800;
}

.normal {
  font-weight: normal;
}

select {
  border-radius: 0;
  padding: $grid $grid * 10 $grid $grid * 2;
  border: 1px solid $slate-grey;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0;
  background-color: $white;
  color: $boost-blue;
  @include rem(font-size, 16px);
  line-height: 2.4rem;
  height: 40px;
  transition: border-color $transition-speed $transition-timing,
    border-radius $transition-speed $transition-timing;

  &:active {
    border-color: $boost-blue;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNNy41ODE2MiAwLjIyMzU5NUwxMy43NzU2IDYuNDk5NjhDMTQuMDc0MSA2Ljc5ODIzIDE0LjA3NDEgNy4yODIyMyAxMy43NzU2IDcuNTgwNzhDMTMuNDc3MSA3Ljg3OTMyIDEyLjk5MzEgNy44NzkzMiAxMi42OTQ1IDcuNTgwNzhMNi40OTk1MSAxLjMwNDY5QzYuMjAwOTYgMS4wMDYxNCA2LjIwMDk2IDAuNTIyMTQzIDYuNDk5NTEgMC4yMjM1OTVDNi43OTgwNiAtMC4wNzQ5NTMyIDcuMjgzMDggLTAuMDc0OTUzMiA3LjU4MTYyIDAuMjIzNTk1WiIgZmlsbD0iIzM0MzhjMCIvPg0KPHBhdGggZD0iTTcuNDk5NzYgMC4yMjMyM0M3Ljc5ODMgMC41MjE3NzkgNy43OTgzIDEuMDA1NzcgNy40OTk3NiAxLjMwNDMyTDEuMzA1MDggNy41NzY4N0MxLjAwNjUzIDcuODc1NDIgMC41MjI1MzQgNy44NzU0MiAwLjIyMzk4NiA3LjU3Njg3Qy0wLjA3NDU2MiA3LjI3ODMyIC0wLjA3NDU2MiA2Ljc5NDMzIDAuMjIzOTg2IDYuNDk1NzhMNi40MTk2OCAwLjIyMzIzQzYuNzE4MjMgLTAuMDc1MzE3OSA3LjIwMTIxIC0wLjA3NTMxNzkgNy40OTk3NiAwLjIyMzIzWiIgZmlsbD0iIzM0MzhjMCIvPg0KPC9zdmc+DQo=);
  }

  appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNNi40MTc4OSA3LjU4MTA5TDAuMjIzOTExIDEuMzA1Qy0wLjA3NDYzNzEgMS4wMDY0NSAtMC4wNzQ2MzcxIDAuNTIyNDYgMC4yMjM5MTEgMC4yMjM5MTFDMC41MjI0NiAtMC4wNzQ2MzcxIDEuMDA2NDUgLTAuMDc0NjM3MSAxLjMwNSAwLjIyMzkxMUw3LjUgNi41QzcuNzk4NTUgNi43OTg1NSA3Ljc5ODU1IDcuMjgyNTQgNy41IDcuNTgxMDlDNy4yMDE0NSA3Ljg3OTY0IDYuNzE2NDQgNy44Nzk2NCA2LjQxNzg5IDcuNTgxMDlaIiBmaWxsPSIjMzQzOGMwIi8+DQo8cGF0aCBkPSJNNi40OTk3NiA3LjU4MTQ2QzYuMjAxMjEgNy4yODI5MSA2LjIwMTIxIDYuNzk4OTEgNi40OTk3NiA2LjUwMDM3TDEyLjY5NDQgMC4yMjc4MThDMTIuOTkzIC0wLjA3MDczMDggMTMuNDc3IC0wLjA3MDczMDggMTMuNzc1NSAwLjIyNzgxOEMxNC4wNzQxIDAuNTI2MzY2IDE0LjA3NDEgMS4wMTAzNiAxMy43NzU1IDEuMzA4OTFMNy41Nzk4MyA3LjU4MTQ2QzcuMjgxMjggNy44ODAwMSA2Ljc5ODMgNy44ODAwMSA2LjQ5OTc2IDcuNTgxNDZaIiBmaWxsPSIjMzQzOGMwIi8+DQo8L3N2Zz4NCg==);
  background-repeat: no-repeat;
  background-position: right 14px center;

  &::-ms-expand {
    display: none;
  }
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'] {
  @include rem(font-size, $font-size-body);
  color: inherit;
  background-color: transparent;
  appearance: none;
  border-radius: 0;
  border: none;
  box-shadow: 0 1px 0 0 $dark-grey;
  transition: box-shadow 0.2s ease-in-out;
  font-family: $font-family-sans-serif;
  max-width: $max-input-width;
}

fieldset {
  margin-left: 0;
  margin-right: 0;
}

.divided {
  border-top: 1px solid $grey;
}

.contentRestricted {
  @include mq($from: M) {
    padding-left: 0;
    padding-right: 0;
  }

  @include mq($from: L) {
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.contentConstrain {
  @include mq($from: M) {
    width: 80%;
    max-width: $max-content-constrained-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.narrowContentWidth {
  // @include mq($from: M) {
  max-width: $max-narrow-content-width;
  margin-left: auto;
  margin-right: auto;
  // }
}

.insetInner {
  max-width: $max-inset-section-content-width;
  margin-left: auto;
  margin-right: auto;
}

.alert-text {
  position: relative;
  padding-left: $grid * 7;

  &:before {
    content: '';
    position: absolute;
    background: url('./assets/alert.svg') center center no-repeat;
    background-size: contain;
    width: $small-icon-size;
    height: $small-icon-size;
    top: calc(50% - #{$small-icon-size / 2});
    left: $grid * 2;
  }
}

.center-on-small {
  @include mq($until: L) {
    text-align: center;

    a {
      text-align: center;
    }
  }
}

.switch-on-alert {
  text-align: center;
  align-self: center;

  @include mq($from: M) {
    text-align: left;
    align-self: flex-start;
  }
}

.welcome-sub {
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-text {
  display: block;
  text-align: center;
  font-size: 43px;
  align-items: center;
}

.heading-sub-welcome {
  display: block;
  margin: auto;
  margin-top: 25px;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: normal;
}

.heading-sub {
  display: block;
  margin: auto;
  width: 50%;
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 8px;
}

.view-height-50 {
  height: 50vh;
}

.view-height-100 {
  height: 100vh;
}

.center-vertically-container {
  position: relative;
}

.center-vertically {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.spacedVertically {
  margin-top: $squashed;
  margin-bottom: $squashed;
}

.spacedTop {
  margin-top: $squashed;
}

.squashedVertically {
  padding-top: $squashed;
  padding-bottom: $squashed;
}

.no-top {
  margin-top: 0;
}

.no-bottom {
  margin-bottom: 0;
}

.d-block {
  display: block;
}

.blockLink {
  display: block;
}

.skip-link {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  position: absolute;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  &:focus {
    clip: auto;
    clip-path: none;
    top: 1rem;
    left: 1.25rem;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0.5rem 1rem;
    overflow: hidden;
    z-index: 3000;
    background: #fff;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.15);
    outline-offset: 0;
  }
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  position: absolute;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
}

.hidden {
  display: none;
}

// Fix for flash of overlay as per https://github.com/pradel/react-responsive-modal/issues/495
.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal {
  animation-fill-mode: forwards !important;
}
