@import '../../styles/colours';
@import '../../styles/mixins';
@import '../../styles/mq';
@import '../../styles/typography';
@import '../../styles/variables';

.Section {
  padding: $compact $grid * 3;
  transition: background-color $transition-speed $transition-timing;

  h1,
  h2,
  h3,
  h4,
  p {
    &:first-child {
      margin-top: 0;
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mq($from: M) {
    padding: $compact $block;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  .Section {
    padding-left: $grid * 3;
    padding-right: $grid * 3;

    @include mq($from: M) {
      padding-left: $grid * 5;
      padding-right: $grid * 5;
    }
  }
}

.none {
}

.alert {
  background-color: $ice-blue;
  background-image: url('../../assets/boostPatternIceBlueMed.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  label {
    color: $black;
  }
}

.info {
  @include colorSection($info, $info-foreground, $info-foreground--secondary);
  background-size: cover;
  background-image: url('../../assets/boostPatternBlueSmall.png');
  background-position: center center;
  background-repeat: no-repeat;
}

.secondary {
  @include colorSection(
    $secondary,
    $secondary-foreground,
    $secondary-foreground--secondary
  );
}

.summary {
  @include colorSection(
    $summary,
    $summary-foreground,
    $summary-foreground--secondary
  );
}

.lightText {
  @include colorSection();
}

.fullTextureBg {
  @include colorSection();
  background-size: cover;
  background-image: url('../../assets/boostPatternBlueSmall.png');
  background-position: center center;
  background-repeat: no-repeat;
}

.fullTextureBgNarrow {
  @include colorSection();
  background-size: cover;
  background-image: url('../../assets/boostPatternBlueSmall.png');
  background-position: center center;
  background-repeat: no-repeat;
  height: 310px;
}

.squashed {
  padding: $squashed $grid * 3;

  @include mq($from: M) {
    padding: $squashed $block;
  }
}

.compact {
  padding: $compact $grid * 3;

  @include mq($from: M) {
    padding-left: $compact;
    padding-right: $compact;
  }
}

.verticalCompact {
  padding-top: $compact;
  padding-bottom: $compact;
}

.horizontalCompact {
  padding: $compact;

  @include mq($from: M) {
    padding-left: 0;
    padding-right: 0;
  }
}

.verticalSpacedOut {
  padding-top: $spaced;
  padding-bottom: $inset-section-margin-top;
}

.divided {
  border-top: 1px solid #e8e8e8;
}

.spaced {
  margin-bottom: $spaced;
}

// Position styles
.insetLogin {
  position: relative;
  margin-top: calc(
    -#{$font-size-mobile-h1 + $compact}
  ); // heading's font size on mobile
  // margin-bottom: calc(
  //   #{$font-size-mobile-h1 + $compact}
  // ); // heading's font size on mobile
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - #{$grid * 4});
  max-width: 464px;

  @include mq($from: L) {
    margin-top: -50px;
    margin-left: auto;
    margin-right: auto;
  }

  &.none {
    background-color: $white;
    border: 1px solid $grey;
  }

  @include mq($from: L) {
    position: absolute;
    width: $inset-login-section-desktop-width;
    top: 50%;
    // inset background takes 8 / 12 grid columns and is positioned to the left, inset section needs to be positioned to the right edge of it, but it's width centered on the edge
    left: calc(
      #{100% - ((4 / 12) * 100%)} - #{$inset-login-section-desktop-width / 2}
    );
    transform: translateY(-50%);
    // If the size of the inset has to follow grid sizes then adapt & use the below
    // width: #{(5 / 12) * 100%};
    // left: #{100% - ((1.5 / 12) * 100%) - ((5 / 12) * 100%)};
    margin-top: 0;
  }
}

.inset {
  position: relative;
  margin-top: -$inset-section-margin-top;
  width: 95%;
  width: calc(100% - #{$grid * 6});
  margin-left: auto;
  margin-right: auto;
  // This is always inset over a colour background, hence it always needs to have white bg
  @include colorSection($white, $slate-grey, $slate-grey--hover);

  @include mq($from: M) {
    width: $inset-section-width;
  }
}
