@import '../../styles/variables';

.Intro {
  width: 100%;

  .children,
  .icon,
  .text,
  .title {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.icon svg {
  margin-bottom: $compact;
}

img.icon {
  width: 100px;
  margin-bottom: $compact;
}

.text {
  margin-bottom: $compact !important;
}

.title {
  margin-bottom: $compact;
}
