// Apply theme colours to the elements within a section.
// By default: white text & no background.

@mixin colorSection(
  $bg-color: transparent,
  $default-color: $white,
  $secondary-color: $white
) {
  background-color: $bg-color;
  color: $default-color;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $default-color;
  }

  a {
    color: $default-color;

    &:hover,
    &:active {
      color: $secondary-color;
    }
  }

  label {
    color: $default-color;
  }

  input {
    color: $default-color;
    box-shadow: 0 1px 0 0 $default-color !important;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $default-color;
    opacity: 0.8;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $default-color;
    opacity: 0.8;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $default-color;
    opacity: 0.8;
  }

  .disabled {
    opacity: 0.5 !important;
  }
}
