@import '../../styles/colours';
@import '../../styles/mq';
@import '../../styles/variables';

.Page {
  position: relative;
  min-height: 100vh;
}

// As above but with extra space because of last section has background color
.extraBottomPadding {
  padding-bottom: (72px + $from-footer);

  @include mq($from: M) {
    padding-bottom: (90px + $from-footer);
  }
}

.headerPadding {
  padding-top: 74px;

  @include mq($from: M) {
    padding-top: 100px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  max-width: $max-content-width;
  margin-left: auto;
  margin-right: auto;
}

.contentPadding {
  padding-bottom: (76px + $from-footer - $grid * 4);

  @include mq($from: L) {
    padding-top: ($block * 0.5);
    padding-bottom: (90px + $from-footer - $grid * 4);
  }
}

.fullInfoBg {
  background-color: $info;

  @include mq($from: M) {
    padding-top: $block * 1.5;
    min-height: 100vh;
  }
}

.fullTextureBg {
  background-color: $slate-grey;
  background-size: cover;
  background-image: url('../../assets/boostPatternBlueSmall.png');
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;

  @include mq($from: XXL) {
    padding-top: $block * 1.5;
  }

  .fullTextureBgNarrow {
    background-color: $slate-grey;
    background-size: cover;
    background-image: url('../../assets/boostPatternBlueSmall.png');
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 100vh;
    height: 310px;
    @include mq($from: XXL) {
      padding-top: $block * 1.5;
    }
  }
}
