@import '../../styles/colours';
@import '../../styles/mq';
@import '../../styles/variables';

.Footer {
  position: relative;
  width: 100%;
  background-color: $slate-grey;
  background-size: cover;
  background-image: url('../../assets/boostPatternDarkBlueSmall.png');
  background-position: center center;
  background-repeat: no-repeat;
}

.content {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  max-width: $max-content-width;
  margin-left: auto;
  margin-right: auto;
  padding: ($grid * 3) ($grid * 2);
  color: #ffffff;

  @include mq($from: M) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: $grid * 4;
    padding-bottom: $grid * 4;
  }

  @include mq($from: XXL) {
    padding-left: 0;
    padding-right: 0;
  }
}

.Links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.InlineSpan {
  display: inline-block;
}

.InlineLink {
  display: inline-block;
  margin-right: 20px;
  color: #fff;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}

.CopyRights {
  display: block;
  margin: 20px 0 0 0;

  .AppVersion {
    color: gray;
    font-size: 1.2rem;

    @include mq($from: M) {
      padding-left: 0.5rem;
      border-color: gray;
      border-style: solid;
      border-width: 0 0 0 2px;
    }
  }

  @include mq($from: M) {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin: 0;
  }
}

.Logo {
  width: 163px;
  height: 76px;

  @include mq($from: M) {
    margin-bottom: 0;
  }
}
