@import '../../styles/colours';
@import '../../styles/mq';
@import '../../styles/variables';

.Button {
  padding: $grid ($grid * 3);
  box-sizing: inherit;
  cursor: pointer;
  text-align: center;
  margin-bottom: $grid * 2;

  @include mq($from: M) {
    margin-bottom: 0;
  }

  & + a {
    margin-top: $grid * 2;
  }
}

.primary,
.secondary,
.secondaryOutline {
  height: $button-height;
  min-width: $button-min-width;
}

.primary {
  background-color: $boost-blue;
  background: linear-gradient(90deg, $gradiant-1-A 0%, $gradiant-1-B 100%);
  color: $white;

  &:hover,
  &:active {
    background: none;
    background-color: $boost-blue--hover;
  }

  &:disabled {
    background: $platinum;
    color: $slate-grey;
  }
}

.secondary {
  background-color: $slate-grey;
  color: $white;

  &:hover,
  &:active {
    outline: 0;
    background-color: $slate-grey--hover;
  }
}

.outlineLight {
  color: $white;
  border: solid 1px $white;

  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.secondaryOutline {
  color: $slate-grey;
  border: solid 1px $slate-grey;

  &:hover,
  &:active {
    color: $slate-grey--hover;
    border: solid 1px $slate-grey--hover;
  }
}

.tertiary {
  background-color: $white;

  &:hover,
  &:active {
    background-color: darken($white, 10%);
  }
}

.link {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  text-decoration: underline;
  color: $black;
  text-align: left;
  display: inline-block;
  color: $boost-blue;

  &:hover,
  &:active {
    color: $boost-blue--hover;
  }
}

.basic {
  margin-bottom: 0;
}

.compact {
  height: auto;
  min-width: $button-compact-min-width;
}
