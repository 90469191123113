@import 'colours';
@import 'variables';

.accordion {
  &__item {
    position: relative;
    cursor: pointer;
    margin: 0 0 2.4rem 0;
    text-align: left;
    border: 1px solid $grey;
    border-left: 0.4rem solid #0c41c9;
    border-radius: 0.8rem;
    overflow: hidden;

    &:hover,
    &--active {
      border-color: #0c41c9;
    }
  }

  &__btn {
    box-sizing: border-box;
    width: 100%;
    padding: 2.4rem;
    text-align: left;
    line-height: 2.8rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #0c192f;

    &:focus {
      outline: 0 none;
    }
  }

  &__icon {
    position: absolute;
    top: 2.2rem;
    right: 2rem;
    transform: rotate(0deg);
    transition: transform 250ms ease-in-out 0s;

    .accordion__item--active & {
      transform: rotate(180deg);
    }
  }

  &__text {
    display: none;
    margin: 2rem 0 0 0;
    padding: 0 2.4rem 2.4rem 2.4rem;

    .accordion__item--active & {
      display: block;
    }
  }
}
