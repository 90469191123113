@font-face {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gilroy-Bold'),
    url('../assets/fonts/Gilroy-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Gilroy-Medium'),
    url('../assets/fonts/Gilroy-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Gilroy-Regular'),
    url('../assets/fonts/Gilroy-Regular.woff') format('woff');
}

$font-size-h1: 40px;
$font-size-h1-larger: 52px;
$font-size-h2: 40px;
$font-size-h3: 30px;
$font-size-h4: 22px;
$font-size-form-heading: 22px;
$font-size-mobile-h1: 34px;
$font-size-mobile-h2: 24px;
$font-size-body: 18px;
$font-size-body-em: 1.8em;
/* because we set html font size to 10px for 16px we need 1.6em */

$font-size-xx-small: 10px;
$font-size-x-small: 12px;
$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-x-large: 22px;
$font-size-xx-large: 34px;

$font-size-nav: 24px;

$font-family-sans-serif: 'Gilroy-Regular', sans-serif;

$default-line-height: 1.45;
