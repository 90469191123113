$grid: 8px;
$block: 64px;

// added after design changes
$squashed: $grid * 3; // vertical spacing on squashed sections (any sections other than intro or main containers)
$compact: $grid * 4;
$spaced: 52px; // vertical spacing on sections & login

$max-content-width: 1040px;
$max-content-constrained-width: 600px;
$max-narrow-content-width: 460px;
$max-input-width: 500px;

// Buttons
$button-border-radius: 100px;
$button-height: 56px;
$button-compact-height: 22px;
$button-min-width: 240px;
$button-compact-min-width: 160px;
$button-horizontal-padding: ($grid * 3);

// Nav
$nav-padding: 128px;
$nav-padding-small: 76px;

$nav-close-padding: 56px;
$nav-close-padding-small: 24px;

$close-button-padding: 10px;
$close-button-image-size: 20px;

// CircleTag
$circle-tag-size: 40px;

// Animation
$transition-speed: 200ms;
$transition-timing: ease-in-out;

//// Media Queries
// Uncomment this if your site isn't responsive. Enjoy your trip to the past!
// $mq-responsive: false;

// Responsive breakpoints, use semantic names
$mq-breakpoints: (
  XS: 320px,
  S: 480px,
  M: 720px,
  L: 980px,
  XL: 1024px,
  XXL: 1280px,
  XXXL: 1440px,
  XXXXL: 1920px,
);

// Define the breakpoint from the $mq-breakpoints list that IE8 should get
$mq-static-breakpoint: XL;
// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints

// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: () !default;
// z-indices
$zindex-nav: 9999;
// Forms
$field-space-top: $grid * 2;
$small-icon-size: 26px;
$alert-arrow-size: $grid;
// Custom widths for elements
$logo-height: 71px;
$logo-width: 158px;
// Inset section width
$inset-login-section-desktop-width: 464px;
$inset-section-margin-top: 40px;
$inset-section-width: 688px;
$max-inset-section-content-width: 336px;
// Forms
$checkbox-size: 24px;
// Footer space
$from-footer: 48px;
