@import '../../styles/colours';
@import '../../styles/mq';
@import '../../styles/rem';
@import '../../styles/typography';
@import '../../styles/variables';

.Balance {
  @include rem(font-size, $font-size-x-small);
  line-height: 1.2;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-right: $grid;

  @include mq($from: 321px) {
    @include rem(font-size, $font-size-small);
  }
  @include mq($from: 375px) {
    @include rem(font-size, $font-size-medium);
    margin-right: 16px;
    line-height: normal;
  }
  @include mq($from: M) {
    flex-direction: row;
    @include rem(font-size, $font-size-x-large);
  }
}

.header {
  color: $slate-grey;
}
.image {
  width: 23px;
  height: auto;

  @include mq($from: S) {
    align-self: baseline;
  }
}

.money {
  text-align: right;
  align-self: center;
}
