@import '../../styles/mq';
@import '../../styles/rem';
@import '../../styles/typography';
@import '../../styles/variables';

// On mobile, title size is the same across all headings (as per h1), from: M, the sizes differ:
.Title {
}

.TitleLarger {
  @include mq($from: M) {
    @include rem(font-size, $font-size-h1-larger);
    line-height: 1.25;
  }
}
