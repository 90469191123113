.block-margin-centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text--centered {
  text-align: center;
}

.center-horizontally {
  display: block;
  margin: 0 auto;
}
