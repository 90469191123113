@import '../../styles/colours';
@import '../../styles/mq';
@import '../../styles/variables';

.Header {
  width: 100%;
  border-bottom: 1px solid $grey;
  padding-left: ($grid * 2);
  padding-right: ($grid * 2);
  position: fixed;
  top: 0;
  z-index: 1010;
  background-color: $white;
}

.HeaderInner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include mq($from: L) {
    width: 100%;
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.HeaderLogo {
  margin: 14px 0;
  padding: 0 $grid;
}

.right {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
}
